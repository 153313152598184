import { RouteContext } from './Page.context';
import { Background, Footer, Header, Main } from 'containers/index';
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import React, { Component } from 'react';
import views from 'views';
import { ScrollBar } from 'components';

export interface PageProps {
  location: Location;
  navigate: NavigateFunction;
}

export interface PageState extends Omit<PageRouteContext, 'update'> {}

export interface PageRouteContext {
  update: (index: number, delay?: number) => void;
  blocked: boolean;
  index: number;
}

class Page extends Component<PageProps, PageState> {
  transitionDuration: number;

  constructor(props: PageProps) {
    super(props);
    this.state = {
      blocked: false,
      index: Math.max(
        views.findIndex(view =>
          view.pathname.includes(props.location.pathname.split('/')[1]),
        ),
        0,
      ),
    };

    this.updateRoute = this.updateRoute.bind(this);

    this.transitionDuration = 500;
  }

  updatePath(index: number) {
    this.props.navigate(views[index].pathname);
  }

  updateRoute(nextRoute: number) {
    if (nextRoute > -1 && nextRoute < views.length) {
      this.setState({ blocked: true });

      let { index: currentRoute } = this.state;
      if (currentRoute < nextRoute) currentRoute += 1;
      if (currentRoute > nextRoute) currentRoute -= 1;

      this.updatePath(nextRoute);
      this.setState({ index: currentRoute }, () => {
        setTimeout(() => {
          if (currentRoute !== nextRoute) {
            this.updateRoute(nextRoute);
          } else {
            this.setState({ blocked: false });
          }
        }, this.transitionDuration);
      });
    }
  }

  render() {
    const { index, blocked } = this.state;
    return (
      <RouteContext.Provider
        value={{ index, blocked, update: this.updateRoute }}
      >
        <div className="page w-full h-full fixed bg-black">
          <Header />
          <Background />
          <Main />
          <Footer />
          <ScrollBar width={(this.state.index / (views.length - 1)) * 100} />
        </div>
      </RouteContext.Provider>
    );
  }
}

const PageWrapper = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return <Page location={location} navigate={navigate} />;
};

export default PageWrapper;
