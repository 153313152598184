const PREFIX = 'UNTERN-BUCHEN: ';

export type StorageData = { [label: string]: string };

export const readStorage: () => StorageData = () =>
  Object.keys(localStorage)
    .filter(key => key.startsWith(PREFIX))
    .reduce((storage: StorageData, key: string) => {
      storage[key.replace(PREFIX, '')] = localStorage[key];
      return storage;
    }, {});

export const updateStorage = (newData: StorageData) =>
  Object.keys(newData).forEach(
    key => (localStorage[`${PREFIX}${key}`] = newData[key]),
  );
