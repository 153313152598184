import { PageRouteContext } from './Page.component';
import { createContext } from 'react';

/**
 * This page route context stores important page routing details,
 * like the current route index, the update logic, update duration and if update is available.
 *
 * @type {PageRouteContext}
 */
export const RouteContext = createContext<PageRouteContext>({
  blocked: false,
  index: 0,
  update(index: number, duration: number | undefined): void {},
});
