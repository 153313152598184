// import './firebase';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Page } from 'containers';
import React from 'react';
import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <BrowserRouter>
    <Page />
  </BrowserRouter>,
);
