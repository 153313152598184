import { RouteContext } from 'containers/Page.context';
import { Client, Request } from '../../utils/databaseHandler';
import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import Alternative from './Alternative/Alternative.component';
import Complete from './Complete.component';
import { Paper } from 'components';
import Problem from './Problem.component';
import Questionnaire from './Questionnaire.component';
import { RouterProps } from 'react-router';
import views from '../config';

export type RegistrationData = Client & Request;

export interface Question {
  field: {
    type: 'text' | 'checkbox' | 'select';
    items?: string[];
  };
  wording: string;
  details?: string;
}

export interface RegisterProps extends RouterProps {}

const Register = () => {
  const { index } = useContext(RouteContext);

  return (
    <Paper
      data-testid="register"
      className="register absolute bottom-0 w-full h-full"
    >
      {/*todo instead of depending on firebase login.. allow manual adjustment here*/}
      {/*  todo to handle a failure.. build a alternative page which can be activated manually.*/}
      {false && <Alternative />}
      {true && (
        <>
          <Routes>
            <Route
              path={`${views[index].pathname}/abschliessen`}
              element={<Complete />}
            />
            <Route
              path={`${views[index].pathname}/problem`}
              element={<Problem />}
            />
            <Route
              path={`${views[index].pathname}/`}
              element={<Questionnaire />}
            />
          </Routes>
        </>
      )}
    </Paper>
  );
};

export default Register;
