import React, {
  ChangeEvent,
  ChangeEventHandler,
  MouseEventHandler,
  ReactNode,
} from 'react';
import { default as ReactSelect } from 'react-select';
import clsx from 'clsx';

export interface InputControlProps {
  value: string;
  className?: string;
  onChange: ChangeEventHandler;
}

export interface TextControlProps extends InputControlProps {
  area?: boolean;
  outline?: boolean;
  placeholder?: string;
}

export interface SelectControlProps extends InputControlProps {
  options: string[];
}

export interface CheckControlProps extends InputControlProps {
  checked?: boolean;
}

export interface ButtonControlProps {
  onClick: MouseEventHandler;
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  outline?: boolean;
}

const Check = ({ checked, className, ...rest }: CheckControlProps) => (
  <input
    {...rest}
    type="checkbox"
    className={clsx(
      'h-4 w-4 appearance-none border-2 bg-black border-yellow focus:outline-none rounded-none mb-2 mt-2',
      checked && 'bg-yellow',
      className,
    )}
  />
);

const Select = ({
  className,
  value = '',
  options = [],
  onChange,
}: SelectControlProps) => {
  const mapReactSelectOption = (o: string) => ({ value: o, label: o });
  return (
    <ReactSelect
      isMulti
      placeholder="auswählen"
      classNames={{
        container: () => 'w-80 mx-auto my-2',
        control: state =>
          'bg-transparent border-yellow rounded-none shadow-none text-yellow',
        placeholder: state => 'underline',
        menu: () => 'rounded-none bg-black border-1 border-yellow',
        menuList: () => 'text-white',
        option: ({ isFocused }) =>
          isFocused ? 'bg-yellow text-black' : 'bg-transparent',
        multiValueLabel: state => 'text-white bg-black rounded-none',
        multiValueRemove: state => 'text-yellow bg-black rounded-none',
      }}
      options={options.map(mapReactSelectOption)}
      value={(value || '')
        .split(',')
        .map(mapReactSelectOption)
        .filter(v => v.value !== '')}
      onChange={values => {
        const value = values && values.map(value => value.label).join(',');
        onChange({ target: { value } } as ChangeEvent<any>);
      }}
    />
  );
};

const Text = ({ area, outline, className, ...rest }: TextControlProps) =>
  area ? (
    <textarea
      {...rest}
      className={clsx(
        'uppercase appearance-none text-yellow bg-black focus:outline-none rounded-none',
        !outline && 'border-b-2 border-yellow',
        outline && 'border-2 border-white',
        className,
      )}
    />
  ) : (
    <input
      autoFocus={true}
      {...rest}
      autoComplete="on"
      className={clsx(
        'appearance-none text-yellow underline uppercase bg-black focus:outline-none rounded-none',
        className,
      )}
    />
  );

const Button = ({
  disabled,
  outline,
  className,
  ...rest
}: ButtonControlProps) => {
  return (
    <button
      {...rest}
      className={clsx(
        'uppercase py-2 px-4 focus:outline-none',
        [
          !disabled &&
            outline &&
            'border border-yellow text-yellow hover:bg-yellow hover:text-black',
          !disabled && !outline && 'hover:text-yellow',
        ],
        [
          disabled &&
            outline &&
            'border border-gray text-gray cursor-not-allowed',
          disabled && !outline && 'text-gray cursor-not-allowed',
        ],

        className,
      )}
    />
  );
};

const Control = { Check, Select, Text, Button };
export default Control;
