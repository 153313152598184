import React from 'react';

const Complete = () => (
  <>
    <div className="text-center text-yellow text-2xl my-6 sm:text-4xl">
      <a href="tel:+493334385256">Vielen Dank</a>
    </div>
    <div className="text-center w-3/4 sm:w-1/2 overflow-scroll text-sm sm:text-base">
      Sobald wir deine Daten erhalten haben, werden wir uns bei dir melden, um
      weitere Schritte zu besprechen.
    </div>
  </>
);
export default Complete;
