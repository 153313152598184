import React, { useEffect, useRef } from 'react';
import { ComponentProps } from './index';
import clsx from 'clsx';

export type NavigationTheme = 'dark' | 'light';

export interface NavigationProps extends ComponentProps {
  list: string[];
  active: number;
  disabled?: boolean;
  theme: NavigationTheme;
  navigate: (index: number) => void;
}

export interface NavigationItemProps extends ComponentProps {
  text: string;
  dark: boolean;
  selected: boolean;
  nextActive: 'left' | 'right';
}

const NavigationItem = ({
  text,
  dark,
  selected,
  nextActive,
  ...componentProps
}: NavigationItemProps) => {
  return (
    <li
      onClick={componentProps.onClick}
      data-testid="navigation-item"
      className={clsx(
        'navigation-item',
        componentProps.className,
        `inline-flex flex-col flex-grow items-center transition-colors duration-150`,
        !selected && `text-gray`,
        dark && 'hover:text-yellow',
        !dark && 'hover:text-white',
        selected && !dark && `text-yellow`,
        selected && dark && `text-yellow`,
      )}
    >
      <div
        data-testid="navigation-item-text"
        className={clsx('navigation-item-text')}
      >
        {text}
      </div>
      <div
        data-testid="navigation-item-bar"
        className={clsx(
          'navigation-item-bar',
          `h-1 w-full transition-transform transition-colors duration-300`,
          !dark && 'bg-yellow',
          dark && 'bg-yellow',
          selected && 'transform scale-x-100',
          !selected && 'transform scale-x-0',
          selected && nextActive === 'right' && 'origin-left',
          !selected && nextActive === 'right' && 'origin-right',
          selected && nextActive === 'left' && 'origin-right',
          !selected && nextActive === 'left' && 'origin-left',
        )}
      />
    </li>
  );
};

const Navigation = ({
  disabled,
  navigate,
  active,
  theme,
  list,
  ...componentProps
}: NavigationProps) => {
  const prevActive = useRef<number>(active);
  const prevDirection = useRef<'left' | 'right'>('right'); // Default direction

  useEffect(() => {
    if (active !== prevActive.current) {
      prevDirection.current = active > prevActive.current ? 'right' : 'left';
      prevActive.current = active;
    }
  }, [active]);

  const direction =
    active === prevActive.current
      ? prevDirection.current
      : active > prevActive.current
        ? 'right'
        : 'left';

  return (
    <ul
      data-testid="navigation"
      className={clsx(
        'navigation',
        componentProps.className,
        'list-none flex w-full',
      )}
    >
      {list.map((item, index) => (
        <NavigationItem
          key={index}
          nextActive={direction}
          className={clsx(
            disabled && 'cursor-not-allowed',
            !disabled && 'cursor-pointer',
          )}
          onClick={() => {
            if (disabled) return;
            navigate(index);
          }}
          dark={theme === 'dark'}
          selected={active === index}
          text={item}
        />
      ))}
    </ul>
  );
};

export default Navigation;
