import { Link, useNavigate } from 'react-router-dom';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { defaultDetails, questions } from './Register.content';
import { readStorage, updateStorage } from 'utils/storageHandler';
import { Control } from 'components';
import { persistClientAndRequest } from 'utils/databaseHandler';
import { RegistrationData } from './Register.component';

const Questionnaire = () => {
  const navigate = useNavigate();
  // const location = useLocation();

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [cachedAnswers, setCachedAnswers] = useState(readStorage());

  useEffect(() => {
    updateStorage(cachedAnswers);
  });

  const questionsCount = Object.keys(questions).length;
  const questionKey = Object.keys(questions)[
    currentQuestion
  ] as keyof RegistrationData;

  return (
    <>
      <div>
        <Control.Button
          onClick={() => setCurrentQuestion(Math.max(currentQuestion - 1, 0))}
          disabled={currentQuestion === 0}
        >
          zurück
        </Control.Button>

        <Control.Button
          outline
          disabled={currentQuestion !== questionsCount - 1}
          onClick={() => {
            if (currentQuestion === questionsCount - 1) {
              persistClientAndRequest(
                cachedAnswers as unknown as RegistrationData,
              )
                .then(() => {
                  navigate('/anmeldung/abschliessen');
                })
                .catch(error => {
                  console.error(error);
                  navigate('/anmeldung/problem');
                });
            }
          }}
        >
          Abschicken
        </Control.Button>
        <Link to="/courses" />
        <Control.Button
          disabled={currentQuestion === questionsCount - 1}
          onClick={() =>
            setCurrentQuestion(
              Math.min(currentQuestion + 1, questionsCount - 1),
            )
          }
        >
          weiter
        </Control.Button>
      </div>
      <div className="text-center">
        <div className="text-1xl mt-6 sm:text-2xl">
          {questions[questionKey].wording}
        </div>
        <div className="w-full">
          {questions[questionKey].field.type === 'select' && (
            <Control.Select
              className="text-center text-xl px-20 my-6"
              options={questions[questionKey].field.items || []}
              value={cachedAnswers[questionKey]}
              onChange={({ target }: ChangeEvent<any>) => {
                setCachedAnswers({
                  ...cachedAnswers,
                  [questionKey]: target.value,
                });
              }}
            />
          )}
          {questions[questionKey].field.type === 'text' && (
            <Control.Text
              className="text-center text-2xl sm:text-4xl my-6 w-full"
              value={cachedAnswers[questionKey] || ''}
              placeholder="deine Antwort hier.."
              onChange={({ target }: ChangeEvent<any>) =>
                setCachedAnswers({
                  ...cachedAnswers,
                  [questionKey]: target.value,
                })
              }
            />
          )}
          {questions[questionKey].field.type === 'checkbox' && (
            <Control.Check
              className="my-6"
              value={cachedAnswers[questionKey] || ''}
              checked={cachedAnswers[questionKey] === 'ja'}
              onChange={({ target }: ChangeEvent<any>) =>
                setCachedAnswers({
                  ...cachedAnswers,
                  [questionKey]: target.checked ? 'ja' : 'nein',
                })
              }
            />
          )}
        </div>
      </div>
      <div className="text-sm text-center sm:text-base m-2 w-3/4 sm:w-1/2 mx-auto">
        {questions[questionKey].details || defaultDetails}
      </div>
    </>
  );
};

export default Questionnaire;
