import React, { ReactNode } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import GoogleMapReact from 'google-map-react';
import { Paper } from '../components';
import { apiKey } from '../firebase';

const officeLocation1 = {
  lat: 52.833647,
  lng: 13.806132,
};

const officeLocation2 = {
  lat: 52.838678,
  lng: 13.73733,
};

const Marker = ({
  lat,
  lng,
  children,
}: {
  lat: number;
  lng: number;
  children: ReactNode;
}) => (
  <div className="text-center">
    <FaMapMarkerAlt className="text-red text-4xl absolute -left-6-left-6 -top-10" />
    <div className="w-40 mt-4 -ml-10 p-2 bg-gray text-white rounded-3xl">
      {children}
    </div>
  </div>
);

const Contact = () => (
  <div className="contact h-full w-full">
    <GoogleMapReact
      // @ts-ignore
      options={
        {
          fullscreenControlOptions: { position: 1 },
          cameraControlOptions: { position: 1 },
        } as any
      }
      bootstrapURLKeys={{ key: apiKey || '' }}
      defaultCenter={{
        lat: (officeLocation1.lat + officeLocation2.lat) / 2,
        lng: (officeLocation1.lng + officeLocation2.lng) / 2,
      }}
      defaultZoom={12}
    >
      <Marker lat={officeLocation1.lat} lng={officeLocation1.lng}>
        <div>
          <div>Büro in Eberswalde</div>
        </div>
      </Marker>
      <Marker lat={officeLocation2.lat} lng={officeLocation2.lng}>
        <div>
          <div>Büro in Finow</div>
        </div>
      </Marker>
    </GoogleMapReact>

    <Paper className="contact-info-box absolute bottom-0 left-0 right-0 h-auto sm:px-10">
      <div className="contact-info-box flex flex-col sm:flex-row justify-evenly w-full">
        <div className="flex flex-col w-full m-2">
          <div className="text-red text-lg sm:text-xl">
            So kannst du uns erreichen:
          </div>
          <div className="flex flex-col text-base justify-around">
            <div className="text-yellow mr-2">Telefon:</div>
            <div className="text-white">
              <a href="tel:+493334385256">03334 385256 (Büro)</a>
            </div>
            <div className="text-yellow mr-2">Fahrlehrer Telefon:</div>
            <div className="text-white">
              <a href="tel:+491727316142">0172 7316142</a>
            </div>
            <div className="text-yellow mr-2">Email:</div>
            <div className="text-white hover:text-yellow">
              <a href="mailto:kontakt@unternbuchen.de">
                kontakt@unternbuchen.de
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full m-2">
          <div className="text-red text-lg sm:text-xl">
            Hier findest du uns:
          </div>
          <div className="flex flex-col text-base justify-around">
            <div className="text-yellow">Büro in Eberswalde:</div>
            <div className="text-white">
              Eisenbahnstraße 31, 16225 Eberswalde
            </div>
            <div className="text-yellow">Büro in Finow:</div>
            <div className="text-white">
              Eberswalder Straße 63, 16227 Eberswalde
            </div>
          </div>
        </div>
      </div>
    </Paper>
  </div>
);

export default Contact;
