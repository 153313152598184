import React, { useContext } from 'react';
import { Navigation } from 'components';
import { RouteContext } from 'containers/Page.context';
import clsx from 'clsx';
import logoSVG from 'assets/images/logo.svg';
import views from 'views';

const Header = () => {
  const { index, blocked, update } = useContext(RouteContext);

  return (
    <header
      data-testid="header"
      className={clsx(
        'header p-2 w-full h-2/12 flex flex-wrap justify-center content-between',
      )}
    >
      <img
        src={logoSVG}
        alt="Fahrschule Untern Buchen"
        className={clsx('logo h-1/3')}
      />

      <Navigation
        list={views.map(view => view.name)}
        theme={views[index].theme}
        disabled={blocked}
        navigate={update}
        active={index}
      />
    </header>
  );
};

export default Header;
