import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';

// TODO: uncomment for testing in emulator
// connectFirestoreEmulator(db, '127.0.0.1', 8080);

export interface MailContent {
  subject: string;
  text: string;
  html?: string;
}

export interface Request {
  'Vorhandene Führerscheine': string;
  Führerscheinklasse: string;
  'Begleitetes Fahren': string;
  Sehhilfe: string;
}

export interface Client {
  Name: string;
  Anschrift: string;
  Staatsangehörigkeit: string;
  Geburtstag: string;
  Telefon: string;
  Email: string;
}

export type ClientWithRequest = Client & { Antrag: Request };

const addOrUpdateClient = async (data: ClientWithRequest) =>
  addDoc(collection(db, 'client'), {
    ...data,
    modified: serverTimestamp(),
  });

export const persistClientAndRequest = (data: Client & Request) =>
  addOrUpdateClient({
    Name: data['Name'] || '',
    Anschrift: data['Anschrift'] || '',
    Staatsangehörigkeit: data['Staatsangehörigkeit'] || '',
    Telefon: data['Telefon'] || '',
    Email: data['Email'] || '',
    Geburtstag: data['Geburtstag'] || '',
    Antrag: {
      Führerscheinklasse: data['Führerscheinklasse'] || '',
      'Vorhandene Führerscheine': data['Vorhandene Führerscheine'] || '',
      'Begleitetes Fahren': data['Begleitetes Fahren'] || 'nein',
      Sehhilfe: data['Sehhilfe'] || 'nein',
    },
  });
