import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';

// NOTE: as this is already a google key, it is used with Google Maps as well
const apiKey = 'AIzaSyDAgEvzik0HlRZutjOZocRHELDTOJATn7o';

const firebaseConfig = {
  apiKey: apiKey,
  authDomain: 'fahrschule-untern-buchen-1927e.firebaseapp.com',
  projectId: 'fahrschule-untern-buchen-1927e',
  storageBucket: 'fahrschule-untern-buchen-1927e.firebasestorage.app',
  messagingSenderId: '13913837082',
  appId: '1:13913837082:web:17e4e1c077f1d922d28ba3',
  measurementId: 'G-46D4DY9M1F',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { apiKey, app, analytics, db };
