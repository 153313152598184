import { Question, RegistrationData } from './Register.component';

const courses = [
  'Klasse AM',
  'Klasse A1',
  'Klasse A2',
  'Klasse A',
  'Klasse B',
  'Klasse BE/B96',
  'Klasse C1',
  'Klasse C1E',
  'Klasse C',
  'Klasse CE',
];

export const defaultDetails: string =
  'Wir benötigen diese Information für eine erfolgreiche Online-Anmeldung.';

export const questions: {
  [key in keyof RegistrationData]: Question;
} = {
  Name: {
    wording: 'Wie ist dein Name?',
    field: { type: 'text' },
  },
  Anschrift: {
    wording: 'Wo wohnst du?',
    field: { type: 'text' },
  },
  Geburtstag: {
    wording: 'Wann wurdest du geboren?',
    field: { type: 'text' },
  },
  Staatsangehörigkeit: {
    wording: 'Welche Nationalität hast du?',
    field: { type: 'text' },
    details:
      'Bedenke, dass wir während der Ausbildung ausschließlich in Deutsch kommunizieren.',
  },
  Email: {
    wording: 'Wie ist deine Emailadresse?',
    field: { type: 'text' },
  },
  Telefon: {
    wording: 'Wie ist deine Telefonnummer?',
    field: { type: 'text' },
    details:
      'Bitte, gib uns eine gültige Telefonnummer, denn wir werden dich umgehend zurückrufen, um allen weiteren Schritte zu besprechen.',
  },
  Führerscheinklasse: {
    wording: 'Welchen Führerschein möchtest du machen?',
    field: { type: 'select', items: courses },
  },
  'Vorhandene Führerscheine': {
    wording: 'Hast du bereits Führerscheine?',
    field: { type: 'select', items: courses },
  },
  'Begleitetes Fahren': {
    wording: 'Möchtest du dich für "Begleitetes Fahren ab 17" anmelden?',
    field: { type: 'checkbox' },
  },
  Sehhilfe: {
    wording: 'Brauchst du eine Sehhilfe?',
    field: { type: 'checkbox' },
  },
};
