import { Paper } from '../components';
import React from 'react';

const AboutUs = () => (
  <Paper className="register absolute bottom-0 w-full h-full">
    <div className="text-center text-1xl sm:text-2xl">
      Herzlich Willkommen bei
    </div>
    <div className="text-center text-yellow text-2xl my-6 sm:text-4xl">
      Fahrschule Untern Buchen
    </div>
    <div className="text-center w-3/4 sm:w-1/2 overflow-scroll text-sm sm:text-base">
      Egal welche Führerscheinklasse du erwerben möchtest, bei uns steht dir ein
      kompetentes Team von der Anmeldung bis zur erfolgreichen Absolvierung der
      praktischen Fahrprüfung zur Seite. Geht nicht, gibt es bei uns nicht!
      <br />
      <br />
      Also warum lange suchen? <strong>Fahrschule Untern Buchen</strong>!
    </div>
  </Paper>
);

export default AboutUs;
