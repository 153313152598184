import { ComponentProps } from './index';
import React from 'react';
import clsx from 'clsx';

export interface PaperProps extends ComponentProps {
  backgroundImage?: string;
}

const Paper = ({ ...componentProps }: PaperProps) => (
  <div
    data-testid="paper"
    children={componentProps.children}
    className={clsx(
      'paper flex flex-col items-center justify-center opacity-80 bg-black text-white p-2',
      componentProps.className,
    )}
  ></div>
);

export default Paper;
