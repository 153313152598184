import { ExternalView, InternalView } from './types';
import AboutUs from './AboutUs.component';
import Contact from './Contact.component';
import Imprint from './Imprint.component';
import Intro from './Intro.component';
import Register from './Register/Register.component';

export default [
  {
    theme: 'light',
    name: 'Intro',
    component: Intro,
    pathname: '/intro',
  },
  {
    theme: 'light',
    name: 'Über uns',
    component: AboutUs,
    pathname: '/ueber-uns',
  },
  {
    theme: 'light',
    name: 'Anmeldung',
    component: Register,
    pathname: '/anmeldung',
  },
  {
    theme: 'dark',
    name: 'Kontakt',
    component: Contact,
    pathname: '/kontakt',
  },
] as InternalView[];

export const imprint: ExternalView = {
  component: Imprint,
  pathname: '/impressum',
  name: 'Impressum',
};
