import React from 'react';

const Problem = () => (
  <>
    <div className="text-center text-1xl sm:text-2xl">Oh Nein..</div>
    <div className="text-center text-red text-2xl my-6 sm:text-4xl">
      <a href="tel:+493334385256">03334 385256</a>
    </div>
    <div className="text-center w-3/4 sm:w-1/2 overflow-scroll text-sm sm:text-base">
      Es gab ein Problem bei deiner Anmeldung. Bitte melde dich telefonisch bei
      uns!
    </div>
  </>
);

export default Problem;
