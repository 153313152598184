import { ComponentProps } from './index';
import React from 'react';
import clsx from 'clsx';

export interface ScrollbarProps extends ComponentProps {
  width: number;
}

const ScrollBar = ({ width, ...componentProps }: ScrollbarProps) => (
  <div
    className={clsx(
      'scrollbar z-50 ',
      'h-1 absolute bottom-0 left-0 right-0',
      'bg-gradient-to-r from-gradient1 via-gradient2 to-gradient3',
      componentProps.className,
    )}
  >
    <div
      data-testid="scrollbar-cover"
      className={clsx(
        'scrollbar-cover',
        'absolute h-full w-full bg-black z-50',
        `transition-transform duration-500 origin-top-right transform scale-x-${Math.floor(
          100 - width,
        )}`,
      )}
    ></div>
  </div>
);

export default ScrollBar;
